import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';

// Polaris
import { BreadcrumbGroup } from '@amzn/awsui-components-react/polaris';

const BreadCrumbController = {
  PageNotFound: [
    {
      text: 'Tmpl',
      href: '/'
    },
    {
      text: 'Page Not Found',
      href: '/404'
    }
  ]
};

interface IProps {
  history:any,
  page:any,
  breadcrumbItems:any,
}
interface IState {
}

class ServiceBreadcrumbs extends Component<IProps, IState> {

  onFollowHandler(ev) {
    ev.preventDefault();
    if (ev.detail.href) {
      this.props.history.push(ev.detail.href);
    }
  }

  render() {
    var crumbs = (this.props.page in BreadCrumbController) ? BreadCrumbController[this.props.page] : [];

    if ('breadcrumbItems' in this.props) {
      crumbs = crumbs.concat(this.props.breadcrumbItems);
    }

    return (
      <BreadcrumbGroup ariaLabel="Breadcrumbs" 
        onFollow={this.onFollowHandler.bind(this)}
        items={crumbs}
      />
    );
  }
}

export default withRouter(ServiceBreadcrumbs);
export {BreadCrumbController};