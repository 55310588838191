//React
import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

// Components
import ServiceAppLayout from '../components/ServiceAppLayout';
import { Grid, Container } from '@amzn/awsui-components-react/polaris';

//Components
class PageNotFound extends Component {
  render() {
    return (
      <ServiceAppLayout
        page="PageNotFound"
        content={<Content />}
      />
    );
  }
}

// The content in the main content area of the App layout
const Content = () => (
  <Container>
    <Grid className="generic-not-found"
      gridDefinition={[
        { colspan: { xxs: 12 } },
        { colspan: { xxs: 12 } },
        { colspan: { xxs: 12 } }]}
    >
      <div className="generic-not-found__content  align-center">
        <div className="generic-not-found__header">
          <h1>404</h1>
        </div>
      </div>
      <div className="generic-not-found__content align-center">
        <div className="align-center color-primary">
          <h1>Sorry, this URL does not exist or is no longer available.</h1>
        </div>
      </div>

      <div className="generic-not-found__content align-center">
        <div className="align-center">
          <h2>Perhaps you were looking for one of the following sections:</h2>
          <ul>
            <li>
              <a href="/">Template Option 1</a>
            </li>
            <li>
              <a href="/">Template Option 2</a>
            </li>
          </ul>
        </div>
      </div>
    </Grid>
  </Container>


);

export default withRouter(PageNotFound);