//React
import React, { Component } from 'react';

// Helpers
import getDefaultLocale from '../helpers/getDefaultLocale';

// Styles
import "../../styles/components/service-footer.scss";
import '../../styles/index.scss'

import { Grid } from '@amzn/awsui-components-react/polaris';

interface IProps {

}
interface IState {
  year: number
}

export default class ServiceFooter extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      year: new Date().getFullYear()
    }
  }

  getLocale() {
    return getDefaultLocale();
  }

  render() {
    console.log(this.getLocale());
    return (
      <footer className="footer">
        <Grid
          disableGutters
          gridDefinition={[
            { colspan: { default: 3, xxs: 12 } },
            { colspan: { default: 7, xxs: 8 } },
            { colspan: { default: 2, xxs: 4 } }]}
        >
          <div className="align-left">
          </div>
          <div id="copyright" className="align-right small-font">
            © 2008 - {this.state.year}, Amazon Web Services, Inc. or its affiliates. All rights reserved.
          </div>
          <div className="align-right small-font">
            <a href="http://aws.amazon.com/privacy/" target="_blank">Privacy Policy</a>
            <a href="http://aws.amazon.com/terms/" target="_blank">Terms of Use</a>
          </div>
        </Grid>
      </footer>
    );
  }
}