// React
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Polaris
import { SideNavigation, Button, SideNavigationProps } from '@amzn/awsui-components-react/polaris';

// Class ServiceNavigation is the Side Navigation component that is used in BasicLayout, CreateForm, ServiceHomepage, and Table flows.
// Implement like this: <ServiceNavigation />
interface IProps {
  history?: string[]
}

interface IStack {
  
}

class ServiceNavigation extends Component<IProps, IStack> {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onFollowHandler(ev) {
    ev.preventDefault();
    if (ev.detail.href) {
      if (ev.detail.href == "#logout") {
        this.logout();
      } else {
        this.props.history?.push(ev.detail.href);
      }
    }
  }

  logout() {
    return
  }

  buildNavigation() {
    let items: SideNavigationProps.Item[] = [
      {
        type: 'section',
        text: 'Tmpl',
        items: [
          { type: 'link', text: 'Getting started', href: '/' }
        ]
      }
    ];


    items.push.apply(items, [
      {
        type: 'section',
        text: 'My Account',
        items: [
          {
            type: "link",
            text: "Logout",
            href: "#logout"
          }
        ]
      }
    ]);


    // Divider and external/functional links
    // items.push.apply(items, [
    //   {
    //     type: 'divider'
    //   },
    //   {
    //     type: "link",
    //     text: "Documentation",
    //     href: "http://documentation.aws.com",
    //     external: true
    //   }
    // ]);

    return items;
  }

  render() {
    return (
      <SideNavigation
        items={this.buildNavigation()}
        activeHref={`#`}
        onFollow={this.onFollowHandler.bind(this)}
      />
    );
  }
}

// const items = this.navLinks();
const items = [
  {
    type: 'section',
    text: 'My Account',
    items: [
      { type: 'link', text: 'Getting started', href: '#/' }
    ]
  },
  {
    type: 'divider'
  },
  {
    type: "link",
    text: "Documentation",
    href: "http://documentation.aws.com",
    external: true
  }
];

export default ServiceNavigation;