// Fill this object with the values found in the config.js file that is stored in your S3 Bucket for local development

let localAmplifyConfig = {
  region: '', // Fill in from region in config.js
  identityPoolRegion: '', // Fill in from region in config.js
  userPoolId: '', // Fill in from userPoolId in config.js
  userPoolWebClientId: '', // Fill in from userPoolWebClientId in config.js
  mandatorySignIn: false, 
  oauth: {
    domain: '', // Fill in from cognitoDomain in config.js
    scope: ['email', 'openid', 'profile'],
    redirectSignIn: `http://${window.location.host}`,
    redirectSignOut: `http://${window.location.host}`,
    responseType: 'code'
    }
}

export default localAmplifyConfig
