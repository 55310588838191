// React
import React, { Component, useState } from 'react';

// Components
import ServiceNavigation from './ServiceNavigation';
import ServiceBreadcrumbs from './ServiceBreadcrumbs';

// Helpers
import useWindowDimensions from '../helpers/useWindowDimensions';

// Polaris
import { AppLayout } from '@amzn/awsui-components-react/polaris';
import { BreadCrumbController } from './ServiceBreadcrumbs';

const DynamicAppLayout = (props) => {
  type properties = {
    breadcrumbs: any
  }
  const windowDimensions = useWindowDimensions();
  const [navigationOpen, setNavigationOpen] = useState(false);
  let defaultProps = {} as properties

  if (props.page in BreadCrumbController) {
    defaultProps.breadcrumbs = <ServiceBreadcrumbs {...props} />
  }

  Object.assign(defaultProps, {
    navigation: <ServiceNavigation />,
    contentType: "default",
    navigationWidth: 325,
    toolsHide: true,
    disableBodyScroll: true,
    navigationOpen: navigationOpen,
    onNavigationChange: ({ detail }) => setNavigationOpen(detail.open),
    headerSelector: ".header",
    footerSelector: ".footer"
  }, props)

  return (
    <AppLayout {...defaultProps} />
  )
}

// Class ServiceAppLayout is the AppLayout component that is used in the pages.
// Implement like this: <ServiceAppLayout page='GenericNotFound'/>

type IProps = {
  className?: string,
  page: string,
  content: any,
  toolsHide?: boolean,
  disableContentPaddings?: boolean
}
class ServiceAppLayout extends Component<IProps> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <DynamicAppLayout {...this.props} />
    );
  }
}

export default ServiceAppLayout;