//React 
import React, { Component, Fragment } from 'react';
import { BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';
import Amplify, { Auth } from 'aws-amplify';
import { withOAuth } from "aws-amplify-react";
import { useState, useEffect } from "react";
import { authConfig } from './amplifyConfig';
//Pages
import ServiceHomePage from './pages/ServiceHomePage';
import PageNotFound from './pages/PageNotFound';

//Components
import ServiceHeader from "./components/ServiceHeader"
import ServiceFooter from "./components/ServiceFooter"
import ErrorBoundary from './ErrorBoundary'

Amplify.configure({
	Auth: authConfig
  })

function Router() {
	const [user, setUser] = useState(null)



	useEffect(() => {
		async function authUser() {
		  await Auth.currentAuthenticatedUser()
			.then((user) => {
			  setUser(user)
			})
			.catch((e) => {
				Auth.federatedSignIn()
			});
		}
		authUser()
	  }, []);


	  if (user != null) {
		return (
			<BrowserRouter forceRefresh={false}>
				<ErrorBoundary>
					<Fragment>
						<ServiceHeader />
							<div className="page">
								<Switch>
									<Route exact path="/" component={ServiceHomePage} />
									<Route component={PageNotFound} />
								</Switch>
							</div>
						<ServiceFooter/>
	    		  </Fragment>
				</ErrorBoundary>
      		</BrowserRouter>
    );
  } else {
    return (
      <div className="App">
        <div className='loader'></div>
      </div>
    )
	}
}

export default withOAuth(Router);