import localAmplifyConfig from './local-amplify-config'

let redirectUrl = window.location.host === 'localhost:3000' ? `http://${window.location.host}` : `https://${window.location.host}`

let authConfig: Object;

if (window.location.host !== 'localhost:3000') {
  authConfig = {
     region: window["tmplConfig"].region,
     identityPoolRegion: window["tmplConfig"].region,
     userPoolId: window["tmplConfig"].userPoolId,
     userPoolWebClientId: window["tmplConfig"].userPoolWebClientId,
     mandatorySignIn: false,
     oauth: {
       domain: window["tmplConfig"].cognitoDomain,
       scope: ['email', 'openid', 'profile'],
       redirectSignIn: redirectUrl,
       redirectSignOut: redirectUrl,
       responseType: 'code'
     }
  }
} else {
  authConfig = localAmplifyConfig
}

export { authConfig }