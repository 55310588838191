//React
import React, { Component } from "react";
import { DataAccessService } from '../core/api';
import * as faker from 'faker';

// Components
import ServiceAppLayout from '../components/ServiceAppLayout';

// Polaris
import { Button, Box, Grid, SpaceBetween, Container } from '@amzn/awsui-components-react/polaris';


// Styles
import "../../styles/pages/service-home-page.scss";

//Components
class ServiceHomePage extends Component {

  render() {
    return (
      <ServiceAppLayout
        className="awsui-util-no-gutters"
        page="ServiceHomePage"
        content={<Content {...this.props} />}
        toolsHide={true}
        disableContentPaddings={true}
      />
    );
  }
}

interface IProps {

}

interface apiResult {
  Id:string,
  Name:string,
  Date:string,
  Data:string
}

interface IState {
  header: string,
  data: apiResult[],
  client: DataAccessService
}

// The content in the main content area of the App layout
// const Content = () => (
class Content extends Component<IProps, IState> {

  constructor(props) {
    super(props)
    this.state = {
      header: '',
      data: [],
      client: new DataAccessService()
    };
  }
  
  generateData = (data) => {
    const newData = {
      Id: faker.random.uuid(),
      Name: faker.name.findName(),
      Date: new Date(),
      Data: faker.lorem.sentence()
    };

    return newData;
  };

  retrieveTSValues = async () => {
    const val = await this.state.client.get('/ts/helloworld');
    this.setState({
      header: 'Results from TS Call',
      data: val as apiResult[] || []
    });
    return val;
  }

  retrieveGOValues = async () => {
    const val = await this.state.client.get('/go/');
    this.setState({
      header: 'Results from GO Call',
      data: val as apiResult[] || []
    });
    return val;
  }

  putTSValues = () => {
    let data = this.generateData('Posted From TypeScript');
    delete data['Id'];
    this.state.client.post('/ts/helloworld', data)
      .then(data => {
        alert(`TS Posted: ${JSON.stringify(data, null, 2)}`);
        this.retrieveTSValues();
      }).catch(err => {
        alert(err);
      });
  }

  putGOValues = async () => {
    let data = this.generateData('Posted From GoLang');
    this.state.client.post('/go/', data)
      .then(data => {
        alert(`GO Posted: ${JSON.stringify(data, null, 2)}`);
        this.retrieveGOValues();
      }).catch(err => {
        alert(err);
      });
  }

  render() {
    return (
      <Box margin={{ bottom: 'l' }}>
        <div className="custom-home__header">
          <Box padding={{ vertical: 'xxl', horizontal: 's' }}>
            <Grid
              gridDefinition={[
                { offset: { l: 2, xxs: 1 }, colspan: { l: 8, xxs: 10 } },
                { colspan: { xl: 6, l: 5, s: 6, xxs: 10 }, offset: { l: 2, xxs: 1 } },
                { colspan: { xl: 2, l: 3, s: 4, xxs: 10 }, offset: { s: 0, xxs: 1 } }
              ]}
            >
              <Box fontWeight="light" padding={{ top: 'xs' }}>
                <span className="custom-home__category">AWS Professional Services</span>
              </Box>
              <div className="custom-home__header-title">
                <Box variant="h1" fontWeight="bold" padding="n" fontSize="display-l" color="inherit">
                  <strong>Hello World!</strong>
                </Box>

                <Box>
                  <p>
                    <Button className="goGETAPI" onClick={this.retrieveGOValues}>Test GET Golang API</Button>
                    <Button className="tsGETAPI" onClick={this.retrieveTSValues}>Test GET TS API</Button>
                  </p>
                  <p>
                    <Button className="goPOSTAPI" onClick={this.putGOValues}>Test PUT Golang API</Button>
                    <Button className="tsPOSTAPI" onClick={this.putTSValues}>Test PUT TS API</Button>
                  </p>
                </Box>
              </div>
            </Grid>
          </Box>
        </div>

        <Box margin={{ top: 's' }} padding={{ top: 'xxl', horizontal: 's' }}>
          <Grid
            gridDefinition={[
              { colspan: { xl: 12, l: 12, s: 12, xxs: 12 } }
            ]}
          >
            <div className="custom-home-main-content-area">
              <SpaceBetween size="l">
                <div>
                  <Container>
                    <div>
                      <p>{this.state.header}</p>
                      <ul>
                        {
                          this.state.data.map((d, index) => {
                            return <li key={index}>{d.Id}<ul><li>{d.Name}</li><li>{d.Date}</li><li>{d.Data}</li></ul></li>
                          })
                        }
                      </ul>
                    </div>
                  </Container>
                </div>
              </SpaceBetween>
            </div>
          </Grid>
        </Box>
      </Box>



    )
  }
}

export { Content }
export default ServiceHomePage;