/*
    This module is intended to be used as the main data fetching library for developers.
    It abstracts away the axios instance and exposes responses and errors in an easy to 
    use way. Common headers such as 'Content-Type' are set here to.

    A lot of this is borrowed from:
    https://github.com/axios/axios/issues/320#issuecomment-219474269

    Usage for this module looks like:
    ```
        import {DataAccessService} from '../core/api'

        const client = new DataAccessService()
        client.get('/users').then(users => {
            console.log(users)
            // Prints the list of users
        })
        client.post('/users',{
            firstName: 'Fred',
            lastName: 'Flintstone'
        }).then(users => {
            console.log(users)
            // Prints Prints [{firstName: 'Fred', lastName: 'Flintstone'}]
        }).catch(err => {})
        
        // You can also await this module too
        async function getUsers() {
            try {
                const client = new DataAccessService()
                const users = await client.get('/users')
                console.log(users)
                // Prints [{firstName: 'Fred', lastName: 'Flintstone'}]
            } catch (error) {
                console.error(error);
            }
        }
    ```

    Method signatures for the get, post, put, delete are the same as the similiarly named axios methods:
    https://github.com/axios/axios#instance-methods
*/
import AjaxService from './ajax-service';

export default class DataAccessService {
    axios: typeof AjaxService
    constructor(options?) {
        options = {
            ...options
        }
        this.axios = AjaxService
        this.axios.defaults.baseURL = options.baseURL || '/api'
    }
    request(method, url, data) {
        let options = {
            method: method,
            url: url,
            responseType: 'json' as 'json'
        };
        if (data && method === 'GET') {
            options["params"] = data;
        } else if (data) {
            options["data"] = JSON.stringify(data);
            options["headers"] = {
                'Content-Type': 'application/json'
            };
        }
        return new Promise((resolve, reject) => {
            this.axios.request(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    error.message = error.message || `${error.status} ${error.statusText}`;
                    reject(error);
                });
        });
    }
    get(url, data?) {
        return this.request('GET', url, data);
    }
    post(url, data) {
        return this.request('POST', url, data);
    }
    put(url, data) {
        return this.request('PUT', url, data);
    }
    delete(url, data?) {
        return this.request('DELETE', url, data);
    }
}