import React from 'react';
import { withRouter } from 'react-router-dom';
import { User } from '../core/user';
import TopNavigation from "@amzn/awsui-components-react/polaris/top-navigation";

// Styles
import "../../styles/components/service-header.scss";

interface IProps {

}


interface IState {
  user: User
}
class ServiceHeader extends React.Component<IProps, IState> {
    constructor(props) {
        super(props)
        this.state = {
            user: new User()
        }
    }
    render() {
        return (<TopNavigation
            className="header"
            identity={{
                href: "/",
                title: "Application Name",
                logo: {
                    src:
                        "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDNweCIgaGVpZ2h0PSIzMXB4IiB2aWV3Qm94PSIwIDAgNDMgMzEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxnIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxyZWN0IGZpbGw9IiMyMzJmM2UiIHN0cm9rZT0iI2Q1ZGJkYiIgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSI0MiIgaGVpZ2h0PSIzMCIgcng9IjIiPjwvcmVjdD4KICAgICAgICA8dGV4dCBmb250LWZhbWlseT0iQW1hem9uRW1iZXItUmVndWxhciwgQW1hem9uIEVtYmVyIiBmb250LXNpemU9IjEyIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHRzcGFuIHg9IjkiIHk9IjE5Ij5Mb2dvPC90c3Bhbj4KICAgICAgICA8L3RleHQ+CiAgICA8L2c+Cjwvc3ZnPgo=",
                    alt: "Service"
                }
            }}
            utilities={[
                {
                    type: "menu-dropdown",
                    text: this.state.user.full_name,
                    description: this.state.user.email,
                    iconName: "user-profile",
                    items: [
                        { id: "profile", text: "Profile", href: `https://phonetool.amazon.com/users/${this.state.user.userId}` },
                        { id: "signout", text: "Sign out" }
                    ]
                }
            ]}
            i18nStrings={{
                searchIconAriaLabel: "Search",
                searchDismissIconAriaLabel: "Close search",
                overflowMenuTriggerText: "More"
            }}
        />
        )
    }
}

export default withRouter(ServiceHeader);